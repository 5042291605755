<template>
  <LiefengContent>
    <template v-slot:title>采集信息</template>
    <template v-slot:toolsbarRight>
      <Form
        :model="searchData"
        :label-colon="true"
        :inline="true"
        class="search"
      >
        <FormItem style="margin-bottom: 0">
          <Input
            v-model="searchData.title"
            placeholder="请输入关键字"
            style="width: 160px"
          ></Input>
        </FormItem>
        <FormItem>
          <DatePicker
            type="daterange"
            @on-change="changeDate"
            :options="dateOption"
            placement="bottom-end"
            placeholder="请选择日期段"
            style="width: 200px"
          ></DatePicker>
        </FormItem>
        <Button
          type="primary"
          icon="ios-search"
          @click="search"
          style="margin-right: 10px"
          >查询</Button
        >
        <Button
          type="primary"
          icon="ios-search"
          @click="searchMany"
          style="margin-right: 10px"
          >查询重复数据</Button
        >
        <!-- <Button
          type="primary"
          @click="openAddModal"
          icon="ios-add"
          style="margin-right: 10px"
          >新增</Button
        >-->
        <Button
          type="primary"
          @click="exportAllMessage"
          style="margin-right: 10px"
        >
          <Icon type="ios-download-outline"></Icon>导出全部信息
        </Button>
        <Button
          type="primary"
          style="margin-right: 10px"
          icon="ios-stats-outline"
          @click="analysisFn"
          >采集分析</Button
        >
        <Button
          type="primary"
          style="margin-right: 10px"
          icon="ios-paper-plane"
          @click="openSendmsg"
          >发社区通知
        </Button>
        <!-- <Button
          type="success"
          icon="ios-cloud-upload-outline"
          style="margin-right: 10px"
          >导入</Button
        >-->
        <Button
          type="primary"
          @click="importExcel"
          icon="ios-add-circle-outline"
          style="margin-right: 10px"
          >导入
        </Button>
        <Button type="primary" @click="exportMethod" style="margin-right: 10px" :loading="exportLoading">
          <Icon type="ios-download-outline"></Icon>
          导出社区统计
        </Button>
        <Button
          type="error"
          icon="ios-arrow-back"
          @click="
            $router.push({
              path: '/questionnaireindex',
              query: { menuId: $core.getUrlParam('menuId') },
            })
          "
          :style="{
            display: !$route.query.id && $route.query.columnCode ? 'none' : '',
          }"
          >返回
        </Button>
      </Form>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :fixTable="true"
        :curPage="page"
        :total="total"
        :pagesizeOpts="[50, 200, 500, 1000]"
        :page-size="pageSize"
        @hadlePageSize="hadlePageSize"
        @tableSelect="tableSelect"
      ></LiefengTable>

      <!-- 采集分析 -->
      <LiefengModal
        title="信息采集数据分析"
        :fullscreen="true"
        :value="analysisStatus"
        @input="analysisStatusFn"
        class="analysis"
      >
        <template v-slot:contentarea>
          <Table
            border
            :span-method="handleSpan"
            :columns="analysisTalbeColumns"
            :data="analysisTableData"
            size="small"
            ref="analysisTable"
          ></Table>
          <LiefengModal
            title="人员信息"
            height="calc(100vh - 160px)"
            width="85%"
            :value="persionStatus"
            @input="persionStatusFn"
            class="persion"
          >
            <template v-slot:contentarea>
              <Table
                border
                :columns="persionTalbeColumns"
                :data="persionTableData"
                size="small"
                ref="analysisTable"
              ></Table>
              <Page
                style="float: right; margin: 10px"
                @on-change="persionPageFn"
                :current="persionCurPage"
                @on-page-size-change="persionPageSizeChange"
                :page-size="persionPageSize"
                :total="persionTotal"
                :page-size-opts="[20, 50, 100, 200]"
                size="small"
                show-total
                show-elevator
                show-sizer
              />
            </template>
          </LiefengModal>
        </template>
        <template v-slot:toolsbar>
          <Button
            @click="analysisStatusFn(false)"
            type="info"
            style="margin-right: 10px"
            >关闭</Button
          >
          <Button type="primary" @click="exportTable">
            <Icon type="ios-download-outline"></Icon>导出
          </Button>
          <Button type="warning" @click="viewChart" style="margin-left: 10px"
            >查看图表</Button
          >
        </template>
      </LiefengModal>
      <!--      图表弹窗-->
      <LiefengModal
        title="查看图表"
        :fullscreen="true"
        :value="viewToggle"
        @input="viewToggleFn"
        class="analysis"
      >
        <template v-slot:contentarea>
          <div id="content">
            <div class="header">
              <div class="header-wrapper">
                <Card class="item" v-for="item in userTitle" :key="item.id">
                  <div class="item-info">
                    <p>
                      第{{ item.index }}题：
                      <span>{{ item.filedName }}</span>
                    </p>
                    <div>
                      <span>{{ item.personNum.toString() }}</span>
                      <span>{{ "题目参加人数" }}</span>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div class="main">
              <template v-if="Object.values(radioObj).length > 0">
                <div
                  class="main-wrapper"
                  v-for="(itemRadio, radioIndex) in radioObj"
                  :key="radioIndex + '-only1'"
                >
                  <div class="table-title">
                    <span>
                      <i>{{
                        itemRadio.fieldType === "radio"
                          ? itemRadio.filedName
                          : ""
                      }}</i>
                    </span>
                    <div>
                      <span
                        :class="itemRadio.status === 0 ? 'active' : ''"
                        @click="toggleChartFn(itemRadio, radioIndex, 0)"
                        >柱状图</span
                      >
                      <span
                        :class="itemRadio.status === 1 ? 'active' : ''"
                        @click="toggleChartDown(itemRadio, radioIndex, 1)"
                        >饼图</span
                      >
                    </div>
                  </div>
                  <div class="v1" v-if="itemRadio.status === 0">
                    <Card
                      style="width: 98%; margin: 50px 1% 0 1%"
                      v-if="ifType"
                    >
                      <Row type="flex" justify="space-between">
                        <Col
                          span="24"
                          style="
                            text-align: center;
                            font-size: 16px;
                            font-weight: bold;
                          "
                        >
                          {{ "单选题" }}
                          <VeHistogram
                            v-if="itemRadio.roadioObj.rows.length !== 0"
                            :extend="hisExtend"
                            :data="itemRadio.roadioObj"
                          ></VeHistogram>
                          <div
                            style="
                              height: 400px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                            v-else-if="itemRadio.roadioObj.rows.length === 0"
                          >
                            暂无数据
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                  <div class="v2" v-else-if="itemRadio.status === 1">
                    <Card
                      style="width: 98%; margin: 50px 1% 0 1%"
                      v-if="ifType2"
                    >
                      <Row
                        style="display: flex; justify-content: center"
                        type="flex"
                        justify="space-between"
                      >
                        <Col
                          span="12"
                          style="
                            text-align: center;
                            font-size: 16px;
                            font-weight: bold;
                          "
                        >
                          {{ "单选题" }}{{ "题目占比率" }}
                          <VePie
                            v-if="itemRadio.roadioObj.rows.length !== 0"
                            style="margin-top: -20px"
                            :data="itemRadio.roadioObj"
                            :settings="settingsparam"
                            :legend="{ show: false }"
                          ></VePie>
                          <div
                            style="
                              height: 400px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                            v-else-if="itemRadio.roadioObj.rows.length === 0"
                          >
                            暂无数据
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </div>
              </template>
              <template v-if="Object.values(checkoutObj).length > 0">
                <div
                  class="main-wrapper"
                  v-for="(itemData, checkoutIndex) in checkoutObj"
                  :key="checkoutIndex + '-only'"
                >
                  <div class="table-title">
                    <span>
                      <i>{{
                        itemData.fieldType === "checkbox"
                          ? itemData.filedName
                          : ""
                      }}</i>
                    </span>
                    <div>
                      <span
                        :class="itemData.status === 0 ? 'active' : ''"
                        @click="toggleChartFn1(itemData, checkoutIndex, 0)"
                        >柱状图</span
                      >
                      <span
                        :class="itemData.status === 1 ? 'active' : ''"
                        @click="toggleChartDown1(itemData, checkoutIndex, 1)"
                        >饼图</span
                      >
                    </div>
                  </div>
                  <div class="v1" v-if="itemData.status === 0">
                    <Card
                      style="width: 98%; margin: 50px 1% 0 1%"
                      v-if="ifType"
                    >
                      <Row type="flex" justify="space-between">
                        <Col
                          span="24"
                          style="
                            text-align: center;
                            font-size: 16px;
                            font-weight: bold;
                          "
                        >
                          {{ "多选题" }}
                          <VeHistogram
                            v-if="itemData.textobj.rows.length !== 0"
                            :extend="hisExtend"
                            :data="itemData.textobj"
                          ></VeHistogram>
                          <div
                            style="
                              height: 400px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                            v-else-if="itemData.textobj.rows.length === 0"
                          >
                            暂无数据
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                  <div class="v2" v-else-if="itemData.status === 1">
                    <Card
                      style="width: 98%; margin: 50px 1% 0 1%"
                      v-if="ifType2"
                    >
                      <Row
                        style="display: flex; justify-content: center"
                        type="flex"
                        justify="space-between"
                      >
                        <Col
                          span="12"
                          style="
                            text-align: center;
                            font-size: 16px;
                            font-weight: bold;
                          "
                        >
                          {{ "多选题" }}{{ "题目占比率" }}
                          <VePie
                            v-if="itemData.textobj.rows.length !== 0"
                            style="margin-top: -20px"
                            :data="itemData.textobj"
                            :settings="settingsparam"
                            :legend="{ show: false }"
                          ></VePie>
                          <div
                            style="
                              height: 400px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                            v-else-if="itemData.textobj.rows.length === 0"
                          >
                            暂无数据
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
        <template v-slot:toolsbar>
          <Button
            @click="viewToggleFn(false)"
            type="info"
            style="margin-right: 10px"
            >关闭</Button
          >
        </template>
      </LiefengModal>
      <!-- 新增 -->
      <LiefengModal
        :title="previewTitle"
        :fullscreen="true"
        :value="previewStatus"
        @input="previewStatusFn"
      >
        <template v-slot:contentarea>
          <PreviewForm
            :previewFormData="previewFormData"
            @saveSuccess="saveSuccess"
            :infoId="infoId"
            :informationId="informationId"
            ref="previewForm"
            v-if="previewStatus"
          ></PreviewForm>
        </template>
        <template v-slot:toolsbar>
          <Button
            type="primary"
            @click="
              cancelModelTip = true;
              previewStatusFn(false);
            "
            style="margin-right: 10px"
            >关闭</Button
          >
          <Button type="primary" @click="saveAddSave">保存</Button>
        </template>
      </LiefengModal>

      <LiefengModal
        title="社区通知"
        :value="sendmsgStatus"
        @input="sendmsgStatusFn"
        width="700px"
        height="400px"
      >
        <template #contentarea>
          <Form
            :model="sendmsgForm"
            :rules="sendmsgValidate"
            :label-width="80"
            ref="sendmsgForm"
          >
            <FormItem label="通知内容" prop="content">
              <Input
                v-model.trim="sendmsgForm.content"
                type="textarea"
                :maxlength="20"
                show-word-limit
                :rows="4"
                placeholder="请填写通知内容，限20字"
              />
            </FormItem>
            <FormItem label="提示说明" prop="remark">
              <Input
                v-model.trim="sendmsgForm.remark"
                :maxlength="20"
                show-word-limit
                placeholder="提示用户下一步操作，限20字"
              />
            </FormItem>
          </Form>
        </template>
        <template #toolsbar>
          <Button
            type="info"
            @click="
              cancelModelTip = true;
              sendmsgStatusFn(false);
            "
            style="margin-right: 10px"
            >取消</Button
          >
          <Button type="primary" @click="saveSendMsg" :loading="sendmsgLoading">
            <span v-if="!sendmsgLoading">发送</span>
            <span v-else>发送中...</span>
          </Button>
        </template>
      </LiefengModal>
      <LiefengModal
        title="查看重复数据"
        :value="manyStatus"
        @input="manyStatusFn"
        :fullscreen="true"
      >
        <template #contentarea>
          <div class="more-table">
            <div>
              <Table
                height="700"
                border
                stripe
                :columns="moreColumns"
                :loading="moreLoading"
                :data="moreTableData"
              ></Table>
            </div>

            <div class="page-div">
              <Page
                style="float: right; margin: 10px"
                @on-change="morePageFn"
                :current="moreCurPage"
                @on-page-size-change="morePageSizeChange"
                :page-size="morePageSize"
                :total="moreTotal"
                :page-size-opts="[20, 50, 100, 200]"
                size="small"
                show-total
                show-elevator
                show-sizer
              />
            </div>
          </div>
        </template>
        <template #toolsbar>
          <Button
            type="info"
            @click="manyStatusFn(false)"
            style="margin-right: 10px"
            >确定</Button
          >
        </template>
      </LiefengModal>
      <LiefengModal
        title="取消报名"
        :value="singUpStatus"
        @input="singUpStatusFn"
        width="400px"
      >
        <template #contentarea>
          <Form :label-width="100">
            <FormItem>
              <span slot="label" class="validate">取消原因</span>
              <Select v-model="cancel" transfer>
                <Option value="0">不满足报名条件</Option>
                <Option value="1">用户自主取消</Option>
                <Option value="2">临时有事来不了</Option>
                <Option value="3">其他</Option>
              </Select>
            </FormItem>
          </Form>
        </template>
        <template #toolsbar>
          <Button
            type="info"
            style="margin-right: 10px"
            @click="
              cancelSingUp = true;
              singUpStatusFn(false);
            "
            >取消</Button
          >
          <Button type="primary" @click="singUpSave">确定</Button>
        </template>
      </LiefengModal>

      <LiefengModal
        title="导入"
        :value="excelStatus"
        @input="exportModalFn"
        class="group"
      >
        <template v-slot:contentarea>
          <Upload
            ref="excelUpload"
            type="drag"
            :action="excelAction"
            name="file"
            :max-size="10240"
            :show-upload-list="true"
            :before-upload="beforeExcelUpload"
            :on-success="myExcel"
            :data="uploadData"
            :headers="uploadHeaders"
            :default-file-list="fileList"
            :format="['xls', 'xlsx', 'xlsb', 'xlsm', 'xlst']"
            :on-format-error="uploadFormatError"
            :on-exceeded-size="uploadSizeError"
          >
            <div style="padding: 20px 0; text-align: center; width: 100%">
              <Icon
                type="ios-cloud-upload"
                size="52"
                style="color: #3399ff"
              ></Icon>
              <p>点击上传，或将文件拖拽到此处</p>
            </div>
          </Upload>
          <div v-if="textStatus">当前选择的文件名称:{{ excelFile.name }}</div>
          <div v-html="errorContent"></div>
          <div style="text-align: center; margin: 20px 0">
            <Button
              type="primary"
              style="margin-right: 20px"
              @click="dowunModal"
              >模板下载</Button
            >
            <Button type="info" @click="submitExcel">确认导入</Button>
          </div>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/questionnairecollect');
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import LiefengContent from "@/components/LiefengContent";
import PreviewForm from "@/views/form/childrens/previewForm";
import VeHistogram from "v-charts/lib/histogram.common";
import VePie from "v-charts/lib/pie.common";

export default {
  data() {
    return {
      //单选
      radioObj: {}, //保存单选对象
      chartRadio: null,
      flag1: {},
      flag2: {},
      //end

      //多选
      checkoutObj: {}, //保存多选对象
      chartCheckout: null,
      //图表切换
      toggle1: {},
      toggle2: {},
      //end

      nub1: 1,
      userTitle: [],
      ifType2: true,
      ifType: true,

      analyzeFieldVosObj: {}, //多选
      radioVosObj: {}, //单选
      settingsparam: {
        // radius: 80
      },
      hisExtend: {
        // 数据过多时显示滚动条，暂时隐藏
        // dataZoom: [
        //   {
        //     show: true,
        //     realtime: true,
        //     start: 0,
        //     end: 50
        //   },
        //   {
        //     type: "inside",
        //     realtime: true,
        //     start: 0,
        //     end: 50
        //   }
        // ],
        series: {
          barMaxWidth: 50,
        },
      },
      chart: 1,
      viewToggle: false,
      endDate: "",
      startDate: "",
      dateOption: {
        shortcuts: [
          //公布时间配置项
          {
            text: "1天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              return [end, start];
            },
          },
          {
            text: "7天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [end, start];
            },
          },
          {
            text: "15天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              return [end, start];
            },
          },
          {
            text: "30天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [end, start];
            },
          },
        ],
      },
      searchData: {},
      talbeColumns: [],
      tableData: [],
      loading: false,
      page: 1,
      total: 0,
      pageSize: 50,
      menuCodeObj: {},
      formId: "",

      //   新增/修改
      previewStatus: false,
      previewTitle: "",
      cancelModelTip: true, //true代表关闭弹窗提示信息
      infoId: "",
      previewFormData: {},

      // 采集分析
      analysisStatus: false,
      searchList: [], //人员信息获取查询列表
      analysisTalbeColumns: [
        {
          type: "index",
          width: 60,
          align: "center",
        },
        {
          title: "题目",
          key: "filedName",
          minWidth: 300,
        },
        {
          title: "答案",
          key: "optionName",
          minWidth: 300,
        },
        {
          title: "所占比例(%)",
          key: "percentage",
          width: 200,
          align: "center",
        },
        {
          title: "参与人数(点击查看详情)",
          key: "personNum",
          width: 200,
          align: "center",
          render: (h, params) => {
            return h(
              "Button",
              {
                props: {
                  type: "error",
                  ghost: true,
                },
                on: {
                  click: () => {
                    this.$Message.loading({
                      content: "正在加载数据，请稍等...",
                      duration: 0,
                    });
                    // 获取人员信息表头数据
                    this.$get(
                      "/datamsg/api/pc/information/v2/selectDynamicFormHeader",
                      {
                        businessType: this.menuCodeObj.businessType,
                        functionType: this.menuCodeObj.functionType,
                        operatorCustGlobalId:
                          parent.vue.loginInfo.userinfo.custGlobalId,
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        oemCode: parent.vue.oemInfo.oemCode,
                        terminal: "2",
                        informationId:
                          this.$route.query.id || this.informationId,
                      }
                    ).then((res) => {
                      if (res.code == 200 && res.dataList) {
                        this.persionTalbeColumns = res.dataList.map((item) => {
                          let paramsObj = {};
                          if (
                            item.fieldType == "image" ||
                            item.fieldType == "signature"
                          ) {
                            paramsObj = {
                              render: (h, params) => {
                                if (!params.row[item.classField])
                                  params.row[item.classField] = [];
                                let answer =
                                  params.row[item.classField].split(",");
                                return answer.map((subItem) => {
                                  return h(
                                    "div",
                                    {
                                      style: {
                                        textAlign: "center",
                                        padding: "10px 0",
                                        display: "inline-block",
                                        marginRight: "10px",
                                        cursor: "pointer",
                                      },
                                    },
                                    [
                                      h(
                                        "a",
                                        {
                                          attrs: {
                                            target: "view_window",
                                            href: subItem,
                                          },
                                        },
                                        [
                                          h("img", {
                                            attrs: {
                                              src: subItem,
                                            },
                                            style: {
                                              width: "40px",
                                              height: "40px",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  );
                                });
                              },
                            };
                          } else if (item.fieldType == "video") {
                            paramsObj = {
                              render: (h, params) => {
                                if (!params.row[item.classField])
                                  params.row[item.classField] = [];
                                let answer =
                                  params.row[item.classField].split(",");
                                return answer.map((subItem) => {
                                  return h(
                                    "div",
                                    {
                                      style: {
                                        textAlign: "center",
                                        padding: "10px 0",
                                        display: "inline-block",
                                        marginRight: "10px",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: () => {
                                          let page = window.open();
                                          var html =
                                            "<body style='background:black'> <div style='width:80%;margin:auto;'> <video controls width='100%' height='90%' autoplay src=" +
                                            subItem +
                                            "></video></div></body>";
                                          page.document.write(html);
                                        },
                                      },
                                    },
                                    [
                                      h("video", {
                                        attrs: {
                                          width: "100%",
                                          height: "100px",
                                          src: subItem,
                                        },
                                      }),
                                    ]
                                  );
                                });
                              },
                            };
                          } else if (item.fieldType == "file") {
                            paramsObj = {
                              render: (h, params) => {
                                if (!params.row[item.classField])
                                  params.row[item.classField] = [];
                                let answer =
                                  params.row[item.classField].split(",");
                                return answer.map((subItem) => {
                                  return h(
                                    "a",
                                    {
                                      attrs: {
                                        href: subItem,
                                        download: subItem.substring(
                                          subItem.lastIndexOf("/") + 9
                                        ),
                                        target: "view_window",
                                      },
                                      style: {
                                        display: "block",
                                      },
                                    },
                                    subItem.substring(
                                      subItem.lastIndexOf("/") + 9
                                    )
                                  );
                                });
                              },
                            };
                          } else if (item.fieldType == "date") {
                            paramsObj = {
                              render: (h, params) => {
                                return h(
                                  "div",
                                  params.row[item.classField]
                                    ? this.$core.formatDate(
                                        new Date(params.row[item.classField]),
                                        "yyyy-MM-dd"
                                      )
                                    : ""
                                );
                              },
                            };
                          } else if (item.fieldType == "cascader") {
                            paramsObj = {
                              render: (h, params) => {
                                return h(
                                  "div",
                                  params.row[item.classField]
                                    ? params.row[item.classField]
                                        .replace(/\#/g, "")
                                        .replace(/\@/g, "")
                                        .replace(/\&[\0-\9]*/g, "")
                                    : ""
                                );
                              },
                            };
                          } else {
                            paramsObj = {
                              key: item.classField,
                            };
                          }
                          return {
                            minWidth: 200,
                            ...paramsObj,
                            renderHeader: (h, params) => {
                              return h("div", {
                                domProps: {
                                  innerHTML: item.pageFieldName,
                                },
                                style: {
                                  width: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                },
                              });
                            },
                          };
                        });
                        res.dataList.map((item) => {
                          if (item.fieldId == params.row.fieldId) {
                            this.searchList = [];
                            this.searchList.push({
                              classField: item.classField,
                              fieldType: item.fieldType,
                              tbField: item.tbField,
                              tbValue: params.row.optionName,
                            });
                          }
                        });
                        // 为表头添加填写人姓名，手机号，填写时间
                        let arr = [
                          {
                            title: "填写人姓名",
                            key: "creator",
                          },
                          {
                            title: "填写人手机号",
                            key: "phone",
                          },
                          {
                            title: "填写时间",
                            key: "gmtCreate",
                          },
                        ];
                        arr.map((item) => {
                          this.persionTalbeColumns.push({
                            title: item.title,
                            minWidth: 200,
                            key: item.key,
                          });
                        });
                        // 获取人员信息
                        this.persionHadlePageSize();
                      } else {
                        this.$Message.error({
                          background: true,
                          content: res.desc,
                        });
                      }
                    });
                  },
                },
              },
              params.row.personNum
            );
          },
        },
      ],
      analysisTableData: [],
      filterTable: [],
      //人员信息
      persionStatus: false,
      persionTalbeColumns: [],
      persionTableData: [],
      persionCurPage: 1,
      persionPageSize: 20,
      persionTotal: 0,

      // 越权进入信息采集
      informationId: "",

      //导出
      exportFormTitleData: [],

      // 社区通知
      sendmsgStatus: false,
      sendmsgForm: {
        content: "",
        remark: "",
      },
      sendmsgLoading: false,
      sendmsgValidate: {
        content: [
          { required: true, message: "请填写通知内容", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请填写提示说明", trigger: "blur" },
        ],
      },
      userList: [],
      openSendmsgIndex: 1, //辨别是否第一次点击发社区通知
      manyStatus: false,
      morePage: 1,
      moreCurPage: 1,
      morePageSize: 20,
      moreTotal: 0,
      moreColumns: [],
      moreLoading: false,
      moreTableData: [],
      chuanColor: "red",
      otherColumns: [],
      lastCusId: "",

      // zhw添加
      singUpStatus: false,
      cancel: "",
      singUpRow: {},
      cancelSingUp: false,
      //excel导入弹窗
      excelStatus: false,
      //excel文件数据
      excelFile: null,
      //修改data
      titleData: {},
      uploadData: {},
      uploadHeaders: {},
      //导入文字显示标识
      textStatus: false,
      isloading: false,
      excelAction: "",
      //excel导入错误信息
      errorContent: null,
      fileList: [],
      singleChoice: [], //单选
      multipleChoice: [], //多选
      dataListArr: [],

      exportLoading:false
    };
  },
  watch: {},
  methods: {
    // zhw重复添加
    // 点击导出全部信息按钮
    exportAllMessage() {
      this.$Modal.confirm({
        title: "温馨提示",
        content: "确认导出全部信息",
        onOk: () => {
          this.$get(
            "/datamsg/api/pc/information/v2/downloadAcquisitionAnalysis",
            {
              businessType: this.menuCodeObj.businessType,
              functionType: this.menuCodeObj.functionType,
              operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
              orgCode: parent.vue.loginInfo.userinfo.orgCode,
              oemCode: parent.vue.oemInfo.oemCode,
              terminal: "2",
              informationId: this.$route.query.id || this.informationId,
              orgLevel: 5,
            }
          ).then((res) => {
            if (res.code == 200) {
              if (res.data) {
                if (
                  res.data.slice(0, 4) == "http" &&
                  res.data.slice(0, 5) !== "https"
                ) {
                  res.data = res.data.replace("http", "https");
                }
              }
              console.log(res.data);
              let link = document.createElement("a");
              link.href = res.data;
              document.body.appendChild(link);
              link.click();
              link.download = "导出全部信息";
              document.body.removeChild(link);
              this.$Message.destroy();
            } else {
              this.$Message.warning({
                content: res.desc,
                background: true,
              });
            }
          });
        },
      });
    },
    // 关闭取消报名模态框
    singUpStatusFn(status) {
      if (!status && this.cancelSingUp) {
        this.$Modal.warning({
          title: "温馨提示",
          content: "取消后将不会进行保存，确认取消吗？",
          onOk: () => {
            this.singUpStatus = status;
            this.cancel = "";
            this.singUpRow = {};
          },
        });
      }
    },
    // 模态框点击确认按钮
    singUpSave() {
      if (this.cancel == "") {
        this.$Message.warning({
          content: "请选择取消原因",
          background: true,
        });
        return;
      } else {
        this.$post("/datamsg/api/pc/information/v2/changeRecordState", {
          idList: this.singUpRow.busiId,
          state: this.cancel,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$Message.success({
                background: true,
                content: "取消成功",
              });
              this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
              });
              this.cancelSingUp = false;
              this.singUpStatus = false;
              this.cancel = "";
            } else {
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          })
          .catch((err) => {
            this.$Message.error({
              background: true,
              content: "取消失败，请联系管理员处理",
            });
          });
      }
    },
    // 获取表格表头信息
    getMoreTableTitle() {
      this.$get("/datamsg/api/pc/information/v2/selectDynamicFormHeader", {
        businessType: this.menuCodeObj.businessType,
        functionType: this.menuCodeObj.functionType,
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        oemCode: parent.vue.oemInfo.oemCode,
        terminal: "2",
        informationId: this.informationId || this.$route.query.id, //跨权限操作，url传informationId，直接从菜单进入采集信息
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            if (res.dataList[0]) {
              this.formId = res.dataList[0].formId;
            }
            this.exportFormTitleData = JSON.parse(JSON.stringify(res.dataList)); //保存表头信息，用于导出
            this.otherColumns = [];
            this.otherColumns = res.dataList.map((item) => {
              let paramsObj = {};
              if (item.fieldType == "image" || item.fieldType == "signature") {
                paramsObj = {
                  render: (h, params) => {
                    if (!params.row[item.classField]) {
                      params.row[item.classField] = "";
                    } else {
                      let answer = params.row[item.classField].split(",");
                      return answer.map((subItem) => {
                        return h(
                          "div",
                          {
                            style: {
                              textAlign: "center",
                              padding: "10px 0",
                              display: "inline-block",
                              marginRight: "10px",
                              cursor: "pointer",
                            },
                          },
                          [
                            h(
                              "a",
                              {
                                attrs: {
                                  target: "view_window",
                                  href: subItem,
                                },
                              },
                              [
                                h("img", {
                                  attrs: {
                                    src: subItem,
                                  },
                                  style: {
                                    width: "40px",
                                    height: "40px",
                                  },
                                }),
                              ]
                            ),
                          ]
                        );
                      });
                    }
                  },
                };
              } else if (item.fieldType == "video") {
                paramsObj = {
                  render: (h, params) => {
                    if (!params.row[item.classField])
                      params.row[item.classField] = [];
                    let answer = params.row[item.classField].split(",");
                    return answer.map((subItem) => {
                      return h(
                        "div",
                        {
                          style: {
                            textAlign: "center",
                            padding: "10px 0",
                            display: "inline-block",
                            marginRight: "10px",
                            cursor: "pointer",
                          },
                          on: {
                            click: () => {
                              let page = window.open();
                              var html =
                                "<body style='background:black'> <div style='width:80%;margin:auto;'> <video controls width='100%' height='90%' autoplay src=" +
                                subItem +
                                "></video></div></body>";
                              page.document.write(html);
                            },
                          },
                        },
                        [
                          h("video", {
                            attrs: {
                              width: "100%",
                              height: "100px",
                              src: subItem,
                            },
                          }),
                        ]
                      );
                    });
                  },
                };
              } else if (item.fieldType == "file") {
                paramsObj = {
                  render: (h, params) => {
                    if (!params.row[item.classField]) {
                      params.row[item.classField] = "";
                    } else {
                      let answer = params.row[item.classField].split(",");
                      return answer.map((subItem) => {
                        return h(
                          "a",
                          {
                            attrs: {
                              href: subItem,
                              download: subItem.substring(
                                subItem.lastIndexOf("/") + 9
                              ),
                              target: "view_window",
                            },
                            style: {
                              display: "block",
                            },
                          },
                          subItem.substring(subItem.lastIndexOf("/") + 9)
                        );
                      });
                    }
                  },
                };
              } else if (item.fieldType == "date") {
                paramsObj = {
                  render: (h, params) => {
                    return h(
                      "div",
                      params.row[item.classField]
                        ? this.$core.formatDate(
                            new Date(params.row[item.classField]),
                            "yyyy-MM-dd"
                          )
                        : ""
                    );
                  },
                };
              } else if (item.fieldType == "cascader") {
                paramsObj = {
                  render: (h, params) => {
                    return h(
                      "div",
                      params.row[item.classField]
                        ? params.row[item.classField]
                            .replace(/\#/g, "")
                            .replace(/\@/g, "")
                            .replace(/\&[\0-\9]*/g, "")
                        : ""
                    );
                  },
                };
              } else {
                paramsObj = {
                  key: item.classField,
                };
              }

              return {
                minWidth: 200,
                ...paramsObj,
                renderHeader: (h, params) => {
                  return h("div", {
                    domProps: {
                      innerHTML: item.pageFieldName,
                    },
                    style: {
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    },
                  });
                },
              };
            });
            this.moreColumns = [
              {
                title: "填写人",
                key: "creator",
                width: 150,
                render: (h, params) => {
                  return h(
                    "span",
                    {
                      style: {
                        color: params.row.color ? params.row.color : "",
                      },
                    },
                    params.row.creator
                  );
                },
              },
              {
                title: "提交手机号",
                key: "phone",
                width: 150,
                render: (h, params) => {
                  return h(
                    "span",
                    {
                      style: {
                        color: params.row.color ? params.row.color : "",
                      },
                    },
                    params.row.phone
                  );
                },
              },
              {
                title: "年龄",
                key: "age",
                width: 150,
                render: (h, params) => {
                  return h(
                    "span",
                    {
                      style: {
                        color: params.row.color ? params.row.color : "",
                      },
                    },
                    params.row.age
                  );
                },
              },
              {
                title: "性别",
                key: "sex",
                width: 150,
                render: (h, params) => {
                  return h(
                    "span",
                    {
                      style: {
                        color: params.row.color ? params.row.color : "",
                      },
                    },
                    params.row.sex
                  );
                },
              },
              {
                title: "身份证号码",
                key: "idNum",
                width: 180,
              },
              {
                title: "所属区县",
                key: "zone",
                width: 150,
                render: (h, params) => {
                  return h(
                    "span",
                    {
                      style: {
                        color: params.row.color ? params.row.color : "",
                      },
                    },
                    params.row.zone
                  );
                },
              },
              {
                title: "所属街道",
                key: "street",
                width: 150,
                render: (h, params) => {
                  return h(
                    "span",
                    {
                      style: {
                        color: params.row.color ? params.row.color : "",
                      },
                    },
                    params.row.street
                  );
                },
              },
              {
                title: "所属社区",
                key: "orgName",
                width: 150,
                render: (h, params) => {
                  return h(
                    "span",
                    {
                      style: {
                        color: params.row.color ? params.row.color : "",
                      },
                    },
                    params.row.orgName
                  );
                },
              },
              {
                title: "提交时间",
                key: "gmtCreate",
                width: 170,
                render: (h, params) => {
                  return h(
                    "span",
                    {
                      style: {
                        color: params.row.color ? params.row.color : "",
                      },
                    },
                    params.row.gmtCreate
                  );
                },
              },

              {
                title: "被填人姓名",
                key: "agentName",
                width: 170,
                render: (h, params) => {
                  return h(
                    "span",
                    {
                      style: {
                        color: params.row.color ? params.row.color : "",
                      },
                    },
                    params.row.agentName
                  );
                },
              },
              {
                title: "被填人手机号",
                key: "agentMobile",
                width: 170,
                render: (h, params) => {
                  return h(
                    "span",
                    {
                      style: {
                        color: params.row.color ? params.row.color : "",
                      },
                    },
                    params.row.agentMobile
                  );
                },
              },
              {
                title: "被填人年龄",
                key: "agentAge",
                width: 170,
                render: (h, params) => {
                  return h(
                    "span",
                    {
                      style: {
                        color: params.row.color ? params.row.color : "",
                      },
                    },
                    params.row.agentAge
                  );
                },
              },
              {
                title: "被填人性别",
                key: "agentSex",
                width: 170,
                render: (h, params) => {
                  return h(
                    "span",
                    {
                      style: {
                        color: params.row.color ? params.row.color : "",
                      },
                    },
                    params.row.agentSex
                  );
                },
              },
            ];
            this.otherColumns.map((item) => {
              this.moreColumns.unshift(item);
            });
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    morePageFn(val) {
      this.moreCurPage = val;
      let data = {
        page: this.moreCurPage,
        pageSize: this.morePageSize,
      };
      this.getManyList(data);
    },
    morePageSizeChange(val) {
      this.morePageSize = val;
      this.moreCurPage = 1;
      let data = {
        pageSize: this.morePageSize,
        page: this.morePage,
      };
      this.getManyList(data);
    },
    manyStatusFn(status) {
      this.manyStatus = status;
    },
    searchMany() {
      this.manyStatus = true;
      this.morePage = 1;
      this.morePageSize = 20
      this.moreCurPage = 1
      this.getManyList({
        page: this.morePage,
        pageSize: this.morePageSize,
      });
      this.getMoreTableTitle();
    },

    pushColor(allArr, colorArr, index) {
      if (index == 0) {
        colorArr.push("#" + Math.random().toString(16).slice(2, 8));
        index++;
        this.pushColor(allArr, colorArr, index);
      } else if (index <= allArr.length - 1) {
        if (allArr[index].custGlobalId == allArr[index - 1].custGlobalId) {
          colorArr.push(colorArr[index - 1]);
          index++;
          this.pushColor(allArr, colorArr, index);
        } else {
          index++;
          colorArr.push("#" + Math.random().toString(16).slice(2, 8));
          this.pushColor(allArr, colorArr, index);
        }
      } else {
        return colorArr;
      }
    },

    getManyList(data) {
      this.moreLoading = true;
      this.$post(
        "/datamsg/api/pc/information/question/selectDistinctDynamicFormDataPage",
        {
          businessType: this.menuCodeObj.businessType,
          functionType: this.menuCodeObj.functionType,
          operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          orgCode: parent.vue.loginInfo.userinfo.orgCode,
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          informationId: this.$route.query.id || this.informationId,
          oemCode: parent.vue.oemInfo.oemCode,
          terminal: "2",
          globalFile: this.searchData.title,
          formId: this.formId,
          page: data.page,
          pageSize: data.pageSize,
          startDate: this.startDate,
          endDate: this.endDate,
        },
        { "Content-Type": "application/json" }
      ).then((res) => {
        this.moreLoading = false;
        if (res.code == 200 && res.dataList) {
          res.dataList.map((item) => {
            item.gmtCreate = item.gmtCreate
              ? this.$core.formatDate(
                  new Date(item.gmtCreate),
                  "yyyy-MM-dd hh:mm:ss"
                )
              : "";
          });
          this.moreTableData = res.dataList.map((item) => {
            item.sex = item.sex == "1" ? "男" : item.sex == "2" ? "女" : "";

            item.agentSex =
              item.agentSex == "1" ? "男" : item.agentSex == "2" ? "女" : "";
            if (item.custGlobalId == null) {
              item.custGlobalId = item.agentCustGlobalId;
            }

            return item;
          });

          console.log("改变后的table", this.moreTableData);
          // let List = this.pushColor(this.moreTableData);
          let List = [];
          this.pushColor(this.moreTableData, List, 0);
          console.log("获取到的", List);
          for (var i = 0; i < List.length; i++) {
            this.moreTableData[i].color = List[i];
          }
          this.morePage = res.currentPage;
          this.morePageSize = res.pageSize;
          this.moreTotal = res.maxCount;
          if (this.moreTableData.length == 20) {
            this.lastCusId = this.moreTableData.pop().custGlobalId;
          }
        } else {
          this.moreLoading = false;
          this.$Message.destroy();
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },

    //切换图表，柱状图和饼图
    toggleChartFn(obj, radioIndex, status) {
      //单选
      obj.status = status;
      this.radioObj = [...this.radioObj];
    },

    toggleChartDown(obj, radioIndex, status) {
      //单选
      obj.status = status;
      this.radioObj = [...this.radioObj];
    },
    toggleChartFn1(obj, checkoutIndex, status) {
      //多选
      obj.status = status;
      this.checkoutObj = [...this.checkoutObj];
    },
    toggleChartDown1(obj, checkoutIndex, status) {
      //多选
      obj.status = status;
      this.checkoutObj = [...this.checkoutObj]; //显示
    },
    //查看图表
    viewChart() {
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0,
      });
      this.viewToggle = true;
      this.ifType = false;
      this.ifType2 = false;
      this.$nextTick(() => {
        console.log(222);
        this.ifType = true;
        this.ifType2 = true;
        this.$Message.destroy();
      });
      this.userTitle = [];
      this.dataListArr.map((item, index) => {
        if (item.fieldType === "radio") {
          this.radioVosObj["data" + index] = {};
          this.radioVosObj["data" + index].rows = [];
          item.analyzeFieldVos.map((a) => {
            this.radioVosObj["data" + index].columns = ["答案", "参加人数"];

            this.radioVosObj["data" + index].rows.push({
              答案: a.optionName,
              参加人数: a.personNum,
            });
            item["roadioObj"] = this.radioVosObj["data" + index];
          });
        } else if (item.fieldType === "checkbox") {
          this.analyzeFieldVosObj["data" + index] = {};
          this.analyzeFieldVosObj["data" + index].rows = [];
          item.analyzeFieldVos.map((i) => {
            this.analyzeFieldVosObj["data" + index].columns = [
              "答案",
              "参加人数",
            ];
            this.analyzeFieldVosObj["data" + index].rows.push({
              答案: i.optionName,
              参加人数: i.personNum,
            });
            item["textobj"] = this.analyzeFieldVosObj["data" + index];
          });
        }

        let indexNum = [];
        const b = item.analyzeFieldVos.reduce((a, b) => a + b.personNum, 0);
        indexNum.push(b);

        this.userTitle.push({
          filedName: item.filedName,
          personNum: indexNum, //人数
          index: index + 1,
        });
      });
      this.checkoutObj = {}; //多选
      this.radioObj = {}; //单选过滤成新的对象
      this.checkoutObj = this.dataListArr.filter(
        (item) => item.fieldType === "checkbox"
      );
      this.radioObj = this.dataListArr.filter(
        (item) => item.fieldType === "radio"
      );

      this.radioObj.forEach((item) => (item.status = 0));
      this.radioObj = [...this.radioObj];
      this.checkoutObj.forEach((item) => (item.status = 0));
      this.checkoutObj = [...this.checkoutObj];

      // console.log(this.checkoutObj, 'checkoutObj')
      // console.log(this.radioObj, 'radioObj')
    },
    viewToggleFn(status) {
      this.viewToggle = status;
    },
    // 发社区通知
    openSendmsg() {
      if (this.tableData.length == 0) {
        this.$Modal.error({
          title: "温馨提示",
          content: "暂无可接收通知的人员",
        });
      } else if (this.openSendmsgIndex == 1) {
        this.$Modal.info({
          title: "温馨提示",
          onOk: () => {
            this.openSendmsgIndex = 2;
            this.sendmsgStatus = true;
          },
          content:
            "默认给所有填写人发送社区通知，若只需给指定填写人发送，请从表格中勾选数据或输入查询条件后再发！",
        });
      } else {
        this.sendmsgStatus = true;
      }
    },
    saveSendMsg() {
      this.$refs.sendmsgForm.validate((status) => {
        if (status) {
          this.sendmsgLoading = true;
          this.$post(
            "/datamsg/api/pc/information/question/pushMessageCustomize",
            {
              functionType: this.menuCodeObj.functionType,
              content: this.sendmsgForm.content,
              remark: this.sendmsgForm.remark,
              userList: (() => {
                let arr = this.userList.map((item) => {
                  return {
                    custGlobalId: item.custGlobalId,
                    orgCode: item.orgCode,
                  };
                });
                return arr.length > 0 ? arr : null;
              })(),
              informationId: this.$route.query.id || this.informationId,
              oemCode: parent.vue.oemInfo.oemCode,
              operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
              globalFile: this.searchData.title,
              startDate: this.startDate ? this.startDate : null,
              endDate: this.endDate ? this.endDate : null,
            },
            { "Content-Type": "application/json" }
          )
            .then((res) => {
              if (res.code == 200) {
                this.sendmsgLoading = false;
                this.$Message.success({
                  background: true,
                  content: "发送成功",
                });
                this.cancelModelTip = false;
                this.sendmsgStatus = false;
                this.userList = [];
                this.hadlePageSize({
                  page: this.page,
                  pageSize: this.pageSize,
                });
              } else {
                this.sendmsgLoading = false;
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              this.sendmsgLoading = false;
              this.$Message.error({
                background: true,
                content: "发送失败",
              });
            });
        }
      });
    },
    tableSelect(arr) {
      let userList = [];

      this.userList.map((item) => {
        if (item.page != this.page) {
          userList.push(item);
        }
      });
      this.userList = userList;
      arr.map((item) => {
        let custGlobalId = "";
        if (item.custGlobalId) {
          this.userList.push({
            custGlobalId: item.custGlobalId,
            orgCode: item.orgCode,
            id: item.id,
            page: this.page,
          });
        } else {
          this.userList.push({
            custGlobalId: item.agentCustGlobalId,
            orgCode: item.orgCode,
            id: item.id,
            page: this.page,
          });
        }
      });
      this.userList = this.$core.onlyList(this.userList, "custGlobalId", "id");
    },
    sendmsgStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.sendmsgStatus = status;
          },
        });
      } else {
        this.sendmsgStatus = status;
        this.$refs.sendmsgForm.resetFields();
      }
    },

    // 日期改变
    changeDate(val) {
      this.startDate = val[0];
      this.endDate = val[1];
    },
    // 导出采集信息表格 old
    exportTableData() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [];
          let filterVal = [];
          this.exportFormTitleData.map((item) => {
            if (item.fieldType == "cascader") {
              tHeader.push(item.pageFieldName);
              filterVal.push(
                item.classField
                  ? item.classField
                      .replace(/\#/g, "")
                      .replace(/\@/g, "")
                      .replace(/\&[\0-\9]*/g, "")
                  : ""
              );
            } else if (item.fieldType != "image" && item.fieldType != "file") {
              tHeader.push(item.pageFieldName);
              filterVal.push(item.classField);
            }
          });
          let talbeColumnsArr = [
            {
              title: "填写人",
              key: "creator",
            },
            {
              title: "提交手机号",
              key: "phone",
            },
            {
              title: "年龄",
              key: "age",
              width: 150,
            },
            {
              title: "性别",
              key: "sex",
              width: 150,
            },
            {
              title: "身份证号码",
              key: "idNum",
              width: 180,
            },
            {
              title: "所属区县",
              key: "zone",
            },
            {
              title: "所属街道",
              key: "street",
            },
            {
              title: "所属社区",
              key: "orgName",
            },
            {
              title: "提交时间",
              key: "gmtCreate",
            },
            {
              title: "是否代填",
              key: "agentCustGlobalId",
            },
            {
              title: "被填人姓名",
              key: "agentName",
            },
            {
              title: "被填人手机号",
              key: "agentMobile",
            },
            {
              title: "被填人年龄",
              key: "agentAge",
            },
            {
              title: "被填人性别",
              key: "agentSex",
            },
          ];
          talbeColumnsArr.map((item) => {
            tHeader.push(item.title);
            filterVal.push(item.key);
          });
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "采集信息列表"
          );
        },
      });
    },

    //采集信息导出  news
    //导出excel
    exportMethod() {
      this.$Modal.confirm({
        title: "温馨提示",
        content: "确认导出全部信息",
        onOk: () => {
          this.exportLoading = true
          this.$Message.loading({
            content: "正在导出数据，请稍等...",
            duration: 0,
          });
          this.$post(
            "/datamsg/api/pc/information/question/downloadDynamicForm",
            {
              businessType: this.menuCodeObj.businessType,
              functionType: this.menuCodeObj.functionType,
              operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
              orgCode: parent.vue.loginInfo.userinfo.orgCode,
              custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
              informationId: this.$route.query.id || this.informationId,
              oemCode: parent.vue.oemInfo.oemCode,
              terminal: "2",
              globalFile: this.searchData.title,
              formId: this.formId,
              startDate: this.startDate,
              endDate: this.endDate,
            },
            { "Content-Type": "application/json" }
          ).then((res) => {
            this.$Message.destroy();
            this.exportLoading = false
            if (res.code == 200) {
              if (res.data) {
                if (res.data.slice(0, 5) == "http") {
                  res.data = res.data.replace("http", "https");
                }
              }
              console.log(res.data);

              let link = document.createElement("a");
              link.download = "采集信息数据导出.xlsx";
              link.href = res.data;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              this.$Message.destroy();
            } else {
              this.$Message.warning({
                content: res.desc,
                background: true,
              });
            }
          });
        },
      });

      //   this.axios({
      //     method: data.method,
      //     url: data.url,
      //     data: data.params,
      //     responseType: 'blob'
      //   }).then((res) => {
      //     const link = document.createElement('a');
      //     let blob = new Blob([res.data], {type: 'application/x-excel'});
      //     link.style.display = 'none';
      //     link.href = URL.createObjectURL(blob);
      //     link.download = data.fileName;
      //     document.body.appendChild(link);
      //     link.click();
      //     document.body.removeChild(link);
      //   }).catch(error => {
      //     this.$Message.warning({
      //       content: error.desc,
      //       background: true
      //     });
      //   });
      // },
      // handledom() {
      //   let data = {
      //     method: 'post',
      //     url: '/datamsg/api/pc/information/question/downloadDynamicForm',
      //     fileName: '采集信息数据详情.xlsx',
      //     params: {
      //       session_id: this.Cookies.get('session')
      //     }
      //   };
      //   this.exportMethod(data);
    },
    //end

    // 新增/修改
    previewStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.previewStatus = status;
          },
        });
      } else {
        this.previewStatus = status;
      }
    },
    //新增获取表单信息
    openAddModal() {
      this.$Message.loading({
        content: "正在获取数据，请稍等...",
        duration: 0,
      });
      this.$get("/datamsg/api/pc/dynamicform/findFormByType", {
        formType: this.$route.query.id || this.informationId,
      })
        .then((res) => {
          this.$Message.destroy();
          if (res.code == 200 && res.data) {
            this.infoId = "";
            this.previewFormData = res.data;
            this.previewTitle = "新增采集信息";
            this.previewStatus = true;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.destroy();
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    // 保存新增修改
    saveAddSave() {
      this.$refs.previewForm.save();
    },
    //人员信息
    persionStatusFn(status) {
      this.persionStatus = status;
    },
    persionHadlePageSize() {
      this.$post(
        "/gateway/syinfopublish/api/pc/information/appointment/queryDynamicFormDataPage",
        {
          businessType: this.menuCodeObj.businessType,
          functionType: this.menuCodeObj.functionType,
          operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          orgCode: parent.vue.loginInfo.userinfo.orgCode,
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          informationId: this.$route.query.id || this.informationId,
          oemCode: parent.vue.oemInfo.oemCode,
          terminal: "2",
          globalFile: this.searchData.title,
          formId: this.formId,
          startDate: "",
          page: this.persionCurPage,
          pageSize: this.persionPageSize,
          endDate: "",
          searchList: this.searchList,
        },
        { "Content-Type": "application/json" }
      )
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item) => {
              item.gmtCreate = this.$core.formatDate(
                new Date(item.gmtCreate),
                "yyyy-MM-dd hh:mm:ss"
              );
            });
            this.persionTableData = res.dataList;
            this.persionTotal = res.maxCount;
            this.persionCurPage = res.currentPage;
            this.persionPageSize = res.pageSize;
            this.$Message.destroy();
            this.persionStatus = true;
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.destroy();
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    persionPageSizeChange(val) {
      this.persionPageSize = val;
      this.persionCurPage = 1;
      this.persionHadlePageSize();
    },
    persionPageFn(val) {
      this.persionCurPage = val;
      this.persionHadlePageSize();
    },
    // 采集分析
    exportTable() {
      this.$refs.analysisTable.exportCsv({
        filename: "信息采集数据分析",
        columns: JSON.parse(JSON.stringify(this.analysisTalbeColumns)).slice(1),
        data: this.analysisTableData,
      });
    },
    handleSpan({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1 || columnIndex === 0) {
        let arr = this.analysisTableData.slice(0, rowIndex);
        if (JSON.stringify(arr).indexOf(row.fieldId) != -1) {
          return [0, 0];
        } else {
          return [row.analyzeFieldVos.length, 1];
        }
      }
    },
    analysisFn() {
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0,
      });
      this.$get("/datamsg/api/pc/information/v2/selectAcquisitionAnalysis", {
        businessType: this.menuCodeObj.businessType,
        functionType: this.menuCodeObj.functionType,
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        oemCode: parent.vue.oemInfo.oemCode,
        terminal: "2",
        informationId: this.$route.query.id || this.informationId,
      })
        .then((res) => {
          this.$Message.destroy();
          if (res.code == 200 && res.dataList) {
            this.analysisTableData = [];
            res.dataList.map((item) => {
              if (item.analyzeFieldVos) {
                item.analyzeFieldVos.map((subItem) => {
                  //liang
                  this.analysisTableData.push({
                    percentage: subItem.percentage, //所占比例
                    optionName: subItem.optionName, //答案
                    personNum: subItem.personNum, //参与人数
                    ...item,
                    dynamicBusiVos: subItem.dynamicBusiVos,
                  });
                });
              }
            });
            this.dataListArr = res.dataList;
            this.analysisStatus = true;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    analysisStatusFn(status) {
      this.analysisStatus = status;
    },

    // 保存成功后执行
    saveSuccess() {
      this.cancelModelTip = false;
      this.previewStatus = false;
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    // 获取表格表头信息
    getTableTitle() {
      this.$get("/datamsg/api/pc/information/v2/selectDynamicFormHeader", {
        businessType: this.menuCodeObj.businessType,
        functionType: this.menuCodeObj.functionType,
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        oemCode: parent.vue.oemInfo.oemCode,
        terminal: "2",
        informationId: this.informationId || this.$route.query.id, //跨权限操作，url传informationId，直接从菜单进入采集信息
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            if (res.dataList[0]) {
              this.formId = res.dataList[0].formId;
            }
            this.exportFormTitleData = JSON.parse(JSON.stringify(res.dataList)); //保存表头信息，用于导出
            this.talbeColumns = res.dataList.map((item) => {
              let paramsObj = {};
              if (item.fieldType == "image" || item.fieldType == "signature") {
                paramsObj = {
                  render: (h, params) => {
                    if (!params.row[item.classField]) {
                      params.row[item.classField] = "";
                    } else {
                      let answer = params.row[item.classField].split(",");
                      return answer.map((subItem) => {
                        return h(
                          "div",
                          {
                            style: {
                              textAlign: "center",
                              padding: "10px 0",
                              display: "inline-block",
                              marginRight: "10px",
                              cursor: "pointer",
                            },
                          },
                          [
                            h(
                              "a",
                              {
                                attrs: {
                                  target: "view_window",
                                  href: subItem,
                                },
                              },
                              [
                                h("img", {
                                  attrs: {
                                    src: subItem,
                                  },
                                  style: {
                                    width: "40px",
                                    height: "40px",
                                  },
                                }),
                              ]
                            ),
                          ]
                        );
                      });
                    }
                  },
                };
              } else if (item.fieldType == "video") {
                paramsObj = {
                  render: (h, params) => {
                    if (!params.row[item.classField])
                      params.row[item.classField] = [];
                    let answer = params.row[item.classField].split(",");
                    return answer.map((subItem) => {
                      return h(
                        "div",
                        {
                          style: {
                            textAlign: "center",
                            padding: "10px 0",
                            display: "inline-block",
                            marginRight: "10px",
                            cursor: "pointer",
                          },
                          on: {
                            click: () => {
                              let page = window.open();
                              var html =
                                "<body style='background:black'> <div style='width:80%;margin:auto;'> <video controls width='100%' height='90%' autoplay src=" +
                                subItem +
                                "></video></div></body>";
                              page.document.write(html);
                            },
                          },
                        },
                        [
                          h("video", {
                            attrs: {
                              width: "100%",
                              height: "100px",
                              src: subItem,
                            },
                          }),
                        ]
                      );
                    });
                  },
                };
              } else if (item.fieldType == "file") {
                paramsObj = {
                  render: (h, params) => {
                    if (!params.row[item.classField]) {
                      params.row[item.classField] = "";
                    } else {
                      let answer = params.row[item.classField].split(",");
                      return answer.map((subItem) => {
                        return h(
                          "a",
                          {
                            attrs: {
                              href: subItem,
                              download: subItem.substring(
                                subItem.lastIndexOf("/") + 9
                              ),
                              target: "view_window",
                            },
                            style: {
                              display: "block",
                            },
                          },
                          subItem.substring(subItem.lastIndexOf("/") + 9)
                        );
                      });
                    }
                  },
                };
              } else if (item.fieldType == "date") {
                paramsObj = {
                  render: (h, params) => {
                    return h(
                      "div",
                      params.row[item.classField]
                        ? this.$core.formatDate(
                            new Date(params.row[item.classField]),
                            "yyyy-MM-dd"
                          )
                        : ""
                    );
                  },
                };
              } else if (item.fieldType == "cascader") {
                paramsObj = {
                  render: (h, params) => {
                    return h(
                      "div",
                      params.row[item.classField]
                        ? params.row[item.classField]
                            .replace(/\#/g, "")
                            .replace(/\@/g, "")
                            .replace(/\&[\0-\9]*/g, "")
                        : ""
                    );
                  },
                };
              } else {
                paramsObj = {
                  key: item.classField,
                };
              }

              return {
                minWidth: 200,
                ...paramsObj,
                renderHeader: (h, params) => {
                  return h("div", {
                    domProps: {
                      innerHTML: item.pageFieldName,
                    },
                    style: {
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    },
                  });
                },
              };
            });
            let talbeColumnsArr = [
              {
                title: "填写人",
                key: "creator",
                width: 150,
              },
              {
                title: "提交手机号",
                key: "phone",
                width: 150,
              },
              {
                title: "年龄",
                key: "age",
                width: 150,
              },
              {
                title: "性别",
                key: "sex",
                width: 150,
              },
              {
                title: "身份证号码",
                key: "idNum",
                width: 180,
              },
              {
                title: "所属区县",
                key: "zone",
                width: 150,
              },
              {
                title: "所属街道",
                key: "street",
                width: 150,
              },
              {
                title: "所属社区",
                key: "orgName",
                width: 150,
              },
              {
                title: "提交时间",
                key: "gmtCreate",
                width: 170,
              },
              {
                title: "是否代填",
                key: "agentCustGlobalId",
                width: 170,
              },
              {
                title: "被填人姓名",
                key: "agentName",
                width: 170,
              },
              {
                title: "被填人手机号",
                key: "agentMobile",
                width: 170,
              },
              {
                title: "被填人年龄",
                key: "agentAge",
                width: 170,
              },
              {
                title: "被填人性别",
                key: "agentSex",
                width: 170,
              },
            ];
            talbeColumnsArr.map((item) => {
              this.talbeColumns.push({
                width: item.width,
                title: item.title,
                key: item.key,
                align: "center",
              });
            });
            // 添加select
            this.talbeColumns.unshift({
              type: "selection",
              width: 60,
              align: "center",
            });
            // this.talbeColumns.push({
            //   width: 170,
            //   title: "操作",
            //   align: "center",
            //   render: (h, params) => {
            //     return h("div", [
            //       h(
            //         "Button",
            //         {
            //           props: {
            //             type: "primary",
            //             size: "small"
            //           },
            //           on: {
            //             click: () => {
            //               this.singUpStatus = true;
            //               this.singUpRow = params.row;
            //             }
            //           }
            //         },
            //         "取消报名"
            //       )
            //     ]);
            //   }
            // });
            // this.talbeColumns.push(
            //   {
            //   fixed: "right",
            //   width: 140,
            //   title: "操作",
            //   align: "center",
            //   render: (h, params) => {
            //     return h('div',[
            //       h('Button',{
            //         props: {
            //           type: "error",
            //           size: "small",
            //         },
            //         style: {
            //           marginRight: '10px'
            //         }
            //       },'取消'),
            //       h(
            //       "Button",
            //       {
            //         props: {
            //           type: "primary",
            //           size: "small",
            //         },
            //         on: {
            //           click: () => {
            //             this.infoId = params.row.id;
            //             this.$Message.loading({
            //               content: "正在加载数据，请稍等...",
            //               duration: 0,
            //             });
            //             this.$get(
            //               "/datamsg/api/pc/dynamicform/findDynamicDataById",
            //               {
            //                 dynamicId: params.row.id,
            //               }
            //             )
            //               .then((res) => {
            //                 this.$Message.destroy();
            //                 if (res.code == 200 && res.data) {
            //                   this.previewFormData = res.data;
            //                   this.previewTitle = "修改采集信息";
            //                   this.previewStatus = true;
            //                 } else {
            //                   this.$Message.error({
            //                     background: true,
            //                     content: res.desc,
            //                   });
            //                 }
            //               })
            //               .catch((err) => {
            //                 console.log(err);
            //                 this.$Message.destroy();
            //                 this.$Message.error({
            //                   background: true,
            //                   content: "获取数据失败，请联系管理员处理",
            //                 });
            //               });
            //           },
            //         },
            //       },
            //       "修改"
            //     )
            //     ])
            //   },
            // });
            this.hadlePageSize({
              page: this.page,
              pageSize: this.pageSize,
            });
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    // 获取表格内容
    hadlePageSize(obj) {
      this.loading = true;
      this.$post(
        "/gateway/syinfopublish/api/pc/information/appointment/queryDynamicFormDataPage",
        {
          businessType: this.menuCodeObj.businessType,
          functionType: this.menuCodeObj.functionType,
          operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          orgCode: parent.vue.loginInfo.userinfo.orgCode,
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          informationId: this.$route.query.id || this.informationId,
          oemCode: parent.vue.oemInfo.oemCode,
          terminal: "2",
          globalFile: this.searchData.title,
          formId: this.formId,
          page: obj.page,
          pageSize: obj.pageSize,
          startDate: this.startDate,
          endDate: this.endDate,
        },
        { "Content-Type": "application/json" }
      )
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item) => {
              this.userList.map((subItem) => {
                if (subItem.id == item.id) {
                  item._checked = true;
                }
              });
              item.gmtCreate = item.gmtCreate
                ? this.$core.formatDate(
                    new Date(item.gmtCreate),
                    "yyyy-MM-dd hh:mm:ss"
                  )
                : "";
            });
            this.tableData = res.dataList.map((item) => {
              item.sex = item.sex == "1" ? "男" : item.sex == "2" ? "女" : "";
              if (item.agentCustGlobalId) {
                item.agentCustGlobalId = "是";
                item.agentSex =
                  item.agentSex == "1"
                    ? "男"
                    : item.agentSex == "2"
                    ? "女"
                    : "";
              } else {
                item.agentCustGlobalId = "否";
              }

              return item;
            });
            this.page = res.currentPage;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    dowunModal() {
      this.$Message.loading({
        content: "正在导出，请稍等...",
        duration: 0,
      });
      // let link = document.createElement("a");
      // link.href =
      //   window.vue.getProxy()["/datamsg"].target +
      //   `/api/pc/information/appointment/downloadImportRecordTemplate?informationId=${this
      //     .$route.query.id || this.informationId}`;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      // this.$Message.destroy();
      // return;
      try{
         this.$get('/gateway/syinfopublish/api/pc/information/appointment/downloadImportRecordTemplate',{
          informationId: this.$route.query.id || this.informationId,
        }).then(res=>{
          if (res.data) {
              if (
                res.data.slice(0, 4) == "http" &&
                res.data.slice(0, 5) !== "https"
              ) {
                res.data = res.data.replace("http", "https");
              }
            }
            let link = document.createElement("a");
            link.href = res.data;
            document.body.appendChild(link);
            link.click();
            link.download = "模板下载";
            document.body.removeChild(link);
            this.$Message.destroy();
        })
      }catch{
           this.$Message.destroy();
           this.$Message.error({
             background: true,
             content: "导出失败，请联系管理员处理",
           });
      }
    },
    exportModalFn(status) {
      this.excelStatus = status;
    },
    //导入按钮
    importExcel() {
      this.excelFile = null;
      this.fileList = [];
      this.textStatus = false;
      this.excelStatus = true;
      this.errorContent = "";
    },

    //确认导入
    submitExcel() {
      if (this.excelFile) {
        this.$refs.excelUpload.post(this.excelFile);
      } else {
        this.$Message.error({
          background: true,
          content: "请先选择文件",
        });
      }
    },
    //导入excel格式错误
    uploadFormatError(file) {
      this.$Notice.warning({
        title: "文件格式不正确",
        desc:
          "文件" +
          file.name +
          "格式不正确，请上传xls,xlsx,xlsb,xlsm或xlst格式的文件",
      });
    },
    //导入excel文件大小超出限制
    uploadSizeError(file) {
      this.$Notice.error({
        title: "导入失败",
        desc: "文件 " + file.name + " 大小已超出限制，请控制在10M以内",
      });
    },
    //excel文件上传成功后执行
    myExcel(res) {
      if (res.code == 200) {
        this.$Message.success({
          background: true,
          content: "导入成功！",
        });
        this.hadlePageSize({
          page: this.page,
          pageSize: this.pageSize,
        });

        if (res.dataList.length != 0) {
          this.errorContent = "";
          res.dataList.map((item, index) => {
            this.errorContent += `<div style="color:red">${item}</div>`;
          });
        } else {
          this.textStatus = false;
          this.excelStatus = false;
        }
      } else {
        this.fileList = [];

        this.$Message.error({
          background: true,
          content: "导入失败,请按照模板格式填写数据！",
        });
      }
    },
    //上传之前
    beforeExcelUpload(file) {
      this.excelAction =
        window.vue.getProxy()["/old"].target +
        "/api/pc/information/question/importRecord";
      this.uploadData.orgCode = parent.vue.loginInfo.userinfo.orgCode;
      this.uploadData.custGlobalId = parent.vue.loginInfo.userinfo.custGlobalId;
      this.uploadData.informationId =
        this.$route.query.id || this.informationId;
      this.uploadHeaders = {
        openId: window.sessionStorage.getItem("openId"),
        Authorization:window.sessionStorage.getItem('accessToken')
      };
      this.excelFile = file;
      this.textStatus = true;
      return false;
    },
  },
  async created() {
    if (this.$route.query.code && this.$route.query.columnCode) {
      //informationId,跨权限操作，直接从菜单进入采集信息
      await this.$get("/datamsg/api/pc/menu/selectColumnCodeInfo", {
        columnCode: this.$route.query.columnCode,
      }).then((res) => {
        if (res.code == 200 && res.data) {
          this.menuCodeObj = res.data;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
      // 根据url上的code，查表单id
      await this.$get("/datamsg/api/pc/information/v2/queryByCode", {
        code: this.$route.query.code,
        businessType: this.menuCodeObj.businessType,
        functionType: this.menuCodeObj.functionType,
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        terminal: "2",
      }).then((res) => {
        if (res.code == 200 && res.data) {
          this.informationId = res.data.id;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    } else {
      // 获取functionType/columnCode等
      await this.$get("/old/api/pc/menu/selectColumnByMenuCode", {
        menuCode: this.$core.getUrlParam("menuId"),
      }).then((res) => {
        if (res.code == 200 && res.data) {
          this.menuCodeObj = res.data;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    }

    this.getTableTitle();
  },
  components: {
    LiefengTable,
    LiefengModal,
    LiefengContent,
    PreviewForm,
    VeHistogram,
    VePie,
  },
};
</script>

<style scoped lang='less'>
#content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  > .header {
    padding: 0 10px;
    box-sizing: border-box;

    > .header-wrapper {
      min-height: 317px;
      display: flex;
      flex-wrap: wrap;

      > .item {
        margin: 0 8px 8px 0;
        width: 260px;
        height: 150px;
        display: flex;
        flex-direction: column;

        /deep/ .ivu-card-body {
          padding: 0;
          height: 100%;
          width: 100%;

          > .item-info {
            height: 100%;
            color: #fff;
            display: flex;
            flex-direction: column;
            background: rgb(45, 140, 240);

            > p {
              font-size: 16px;
              font-weight: 600;
              padding: 10px 5px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              max-height: 40%;
              > span {
                font-size: 15px;
                font-weight: 500;
              }
            }

            > div {
              flex: 1;
              justify-content: center;
              align-items: center;
              display: flex;
              flex-direction: column;
              align-items: center;

              > span:first-child {
                font-size: 20px;
                font-weight: 800;
              }
            }
          }
        }
      }
    }
  }

  > .main {
    > .main-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      margin-top: 30px;

      > .table-title {
        display: flex;
        align-items: center;

        > span {
          font-weight: 700;
          font-size: 18px;

          i {
            font-style: normal;
          }
        }

        > div {
          margin-left: 10px;
          border: 1px solid #ccc;
          box-sizing: border-box;
          display: flex;

          > span {
            cursor: pointer;
            font-size: 18px;
            box-sizing: border-box;
            width: 70px;
            text-align: center;
            padding: 5px;

            &:first-child {
              border-right: 1px solid #ccc;
            }

            &.active {
              box-sizing: border-box;
              background: #2db7f5;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

/deep/#toolsbarRight {
  width: 1000%;
}
.search {
  .ivu-form-item {
    margin-bottom: 0;
  }
}
/deep/.ivu-modal-close {
  display: none;
}
.group {
  /deep/.ivu-modal-close {
    display: block;
  }
}
.analysis {
  .ivu-table-wrapper {
    height: calc(100vh - 123px);
  }
  /deep/.ivu-table-tip {
    height: calc(100vh - 160px);
    table {
      height: 100%;
    }
  }
  /deep/.ivu-table {
    overflow: auto;
  }
}
.persion {
  .ivu-table-wrapper {
    height: calc(100vh - 218px);
  }
  /deep/.ivu-table-tip {
    height: calc(100vh - 252px);
    table {
      height: 100%;
    }
  }
  /deep/.ivu-table {
    overflow: auto;
  }
  /deep/.ivu-table-body {
    height: calc(100% - 38px);
  }
  /deep/.ivu-modal-close {
    display: block;
  }
}
</style>

